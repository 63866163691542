

import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import ActionButton from '@/components/Button/Button.vue';
import Chip from '@/components/commonComponents/Chip.vue';
import { Filter } from '@/types/ListTypes';
import { find } from 'lodash';

interface ListOptionMetadata {
  group: null|number,
  label: null|string,
  'label_color': null|string,
  'label_text_color': null|string,
}

interface ListOption {
  const: null|{
    'user_id': number,
    'assignment_id': number,
  },
  title: string,
  metadata: ListOptionMetadata,
}

type ListOptions = Array<ListOption>

interface GroupValue {
  'assignment_id': number,
  'user_id': number,
}

interface GroupOption {
  text: string,
  value: GroupValue|null,
  labelColor: string,
  labelTextColor: string,
}

type GroupOptions = Array<GroupOption>;

interface Group {
  groupName: string,
  groupId: number,
  options: GroupOptions,
}

interface EmptyGroup {
  text: string,
  value: null,
  groupId: null,
}

type Groups = Array<Group|EmptyGroup>

@Component({
  name: 'MultiselectGroupedFilter',
  components: {
    ActionButton,
    Chip,
  },
})
export default class MultiselectGroupedFilter extends Vue {
  @Prop({ required: true })
  private readonly filter!: Filter;

  private groups: Groups = []

  private inputValues: Array<any> = [];

  mounted(): void {
    this.groups = this.getFilterOptions();
    this.inputValues = this.filter.data as Array<any> ?? [];
  }

  protected isSelected(selection: GroupValue|null): boolean {
    if (selection === null) {
      return this.inputValues.indexOf(null) !== -1;
    }
    return find(this.inputValues, selection) !== undefined;
  }

  protected isWholeGroupSelected(groupId: number|null): boolean {
    const amountOfSelectedGroupOptions = this.getAmountOfSelectedGroupOptions(groupId);
    const amountOfGroupOptions = this.getAmountOfGroupOptions(groupId);

    return amountOfSelectedGroupOptions === amountOfGroupOptions;
  }

  protected isGroupIndeterminate(groupId: number|null): boolean {
    const amountOfSelectedGroupOptions = this.getAmountOfSelectedGroupOptions(groupId);
    const amountOfGroupOptions = this.getAmountOfGroupOptions(groupId);

    return amountOfSelectedGroupOptions > 0
      && amountOfSelectedGroupOptions < amountOfGroupOptions;
  }

  protected toggleGroup(groupId: number|null): void {
    const groupValues = this.getGroupOptionValues(groupId) as Array<GroupValue>;
    const amountOfSelectedGroupOptions = this.getAmountOfSelectedGroupOptions(groupId);

    if (amountOfSelectedGroupOptions < groupValues.length) {
      this.inputValues = [...this.inputValues, ...groupValues]
        .map((value) => (value !== null ? JSON.stringify(value) : null))
        .filter((value, index, array) => array.indexOf(value) === index)
        .map((value) => (value !== null ? JSON.parse(value) : null));
    } else {
      const stringGroupValues = groupValues.map((value) => JSON.stringify(value));
      this.inputValues = this.inputValues
        .map((value) => (value !== null ? JSON.stringify(value) : null))
        .filter((value) => (value !== null ? !stringGroupValues.includes(value) : true))
        .map((value) => (value !== null ? JSON.parse(value) : null));
    }
  }

  private getAmountOfSelectedGroupOptions(groupId: number|null): number {
    return this.getSelectedGroupOptions(groupId).length;
  }

  private getSelectedGroupOptions(groupId: number|null): Array<GroupValue|null> {
    const groupOptionValues = this.getGroupOptionValues(groupId);

    return groupOptionValues.filter((object: { [key: string]: any }|null) => {
      if (object === null) {
        return false;
      }

      return this.inputValues.find((object2: { [key: string]: any }|null) => {
        const amountOfProperties = Object.keys(object).length;
        let amountOfEqualValues = 0;
        Object.keys(object).forEach((key) => {
          if (object2 && object[key] === object2[key]) {
            amountOfEqualValues += 1;
          }
        });

        return amountOfEqualValues === amountOfProperties;
      });
    });
  }

  private getAmountOfGroupOptions(groupId: number|null): number {
    return this.getGroupOptionValues(groupId)?.length;
  }

  private getGroupOptionValues(groupId: number|null): Array<GroupValue|null> {
    const group = this.getGroup(groupId);

    // 'Empty' option
    if ((group.groupId === null)) {
      return [null];
    }

    return group?.options.map((option) => option.value);
  }

  private getGroup(groupId: number|null): Group|EmptyGroup {
    const groups = Object.values(this.groups);
    const requestedGroup = groups.find((group) => group.groupId === groupId);

    if (requestedGroup === undefined) {
      throw new Error(`No group found with the groupID: "${groupId}"`);
    }

    return requestedGroup;
  }

  public getFilterOptions(): Groups {
    const options = this.filter.metadata?.options as ListOptions;
    const groups: Groups = [];

    options.forEach((option) => {
      const { title } = option;
      const assignmentId = option.const?.assignment_id ?? null;
      const userId = option.const?.user_id ?? null;
      const { label: groupName, label_color: labelColor, label_text_color: labelTextColor } = option.metadata;

      if (groupName === null || userId === null || assignmentId === null || labelColor === null || labelTextColor === null) {
        groups.unshift({ groupId: null, text: title, value: null });
        return;
      }

      const groupOption = {
        text: title,
        value: {
          user_id: userId,
          assignment_id: assignmentId,
        },
        labelColor,
        labelTextColor,
      };

      const group = groups.find((element) => ((element as Group).options?.[0].value as GroupValue).assignment_id === assignmentId);

      if (group === undefined) {
        groups.push({
          groupName,
          groupId: assignmentId,
          options: [groupOption],
        });
      } else {
        (group as Group).options.push(groupOption);
      }
    });

    return groups;
  }

  @Watch('filter.data', { deep: true })
  private syncFilterDataWithInputData(): void {
    this.inputValues = this.filter.data as Array<unknown> ?? [];
  }

  public applyFilter(): void {
    this.filter.data = this.inputValues;
    this.filter.applied = this.inputValues.length > 0;

    this.$emit('fetchData');
  }
}

