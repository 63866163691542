
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Filter } from '@/types/ListTypes';
import MultiselectGroupedFilter
  from '@/components/DataTable/DataTableFilter/MultiselectGroupedFilter.vue';

@Component({
  name: 'AssignmentsFilter',
  components: {
    MultiselectGroupedFilter,
  },
})
export default class AssignmentsFilter extends Vue {
  @Prop({ required: true })
  private readonly filter!: Filter;
}
